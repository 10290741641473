import { DateTime } from 'luxon';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import {
  parseAsArrayOf,
  parseAsBoolean,
  parseAsInteger,
  parseAsIsoDateTime,
  parseAsString,
  createParser,
} from 'nuqs';
import { createSerializer } from 'nuqs';

export const searchEndpoint = '/search';

export const defaultOriginName = 'Paris';
export const defaultCheckInDate = DateTime.local()
  .set({ weekday: 2 })
  .plus({ weeks: 1 })
  .set({ hour: 7, minute: 0, second: 0, milliseconds: 0 })
  .toJSDate() as Date;

export const MAX_TOTAL_TRAVELERS = 40;

export const defaultGuestAdults = 1;
export const defaultGuestYouths = 0;
export const defaultGuestChildren = 0;
export const defaultGuestInfants = 0;
export const defaultGuestSeniors = 0;
export const defaultGuestBikes = 0;

export const defaultPassType = 'PF';
export const defaultPassClass = 2;
export const defaultTravelDays = '4D';

export const defaultTrip = [];

export async function goToSearchKeepingState(
  router: AppRouterInstance,
  searchParams: URLSearchParams | null
) {
  await goToPageKeepingState(router, searchParams, searchEndpoint);
}

async function goToPageKeepingState(
  router: AppRouterInstance,
  searchParams: URLSearchParams | null,
  newPath: string
) {
  const searchStr = `?${searchParams?.toString()}`;
  const newUrl = `${newPath}${searchStr}`;

  await router.push(newUrl);
}

export function goToExternalPageKeepingState(
  searchParams: URLSearchParams | null,
  newPath: string,
  externalParams?: string,
  anchor?: string
) {
  let searchStr = `?${searchParams?.toString()}`;
  if (externalParams) {
    searchStr += `&${externalParams}`;
  }
  if (anchor) {
    searchStr += `#${anchor}`;
  }
  const newUrl = `${newPath}${searchStr}`;

  window.open(newUrl);
}

//If you need the parameters to be updated straight away on server side
//you need to add "shallow: false" option

const parseAsCheckInDate = createParser({
  parse(queryValue) {
    const parsedDate = DateTime.fromISO(queryValue);
    if (parsedDate.isValid) {
      const today = DateTime.now().startOf('day');
      return parsedDate < today ? null : parsedDate.toJSDate();
    }
    return null;
  },
  serialize(value) {
    return value ? DateTime.fromJSDate(value).toISO() : '';
  },
}).withDefault(defaultCheckInDate);

export const searchParamsParsers = {
  origin: parseAsString.withDefault(defaultOriginName).withOptions({ shallow: false }),
  originNoDefault: parseAsString.withOptions({ shallow: false }),
  destination: parseAsString.withOptions({ shallow: false }),
  checkIn: parseAsCheckInDate,
  checkOut: parseAsIsoDateTime,
  guestAdults: parseAsInteger.withDefault(defaultGuestAdults),
  guestYouths: parseAsInteger.withDefault(defaultGuestYouths),
  guestChildren: parseAsInteger.withDefault(defaultGuestChildren),
  guestInfants: parseAsInteger.withDefault(defaultGuestInfants),
  guestSeniors: parseAsInteger.withDefault(defaultGuestSeniors),
  guestBikes: parseAsInteger.withDefault(defaultGuestBikes),
  nightTrain: parseAsBoolean,
  skiMode: parseAsBoolean,

  passType: parseAsString.withDefault(defaultPassType),
  passClass: parseAsInteger.withDefault(defaultPassClass),
  passTravelDays: parseAsString.withDefault(defaultTravelDays),

  trip: parseAsArrayOf(parseAsString).withDefault(defaultTrip),
};

const searchParamsSerializer = createSerializer(searchParamsParsers);
// TODO: We keep this for now but it will be removed when we are sure we handle correctly transition between pages
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getSearchUrlFromParams = (
  params: Partial<{
    origin?: string;
    destination?: string;
    checkIn?: Date;
    checkOut?: Date;

    guestAdults?: number;
    guestChildren?: number;
    guestInfants?: number;
    guestSeniors?: number;
    guestBikes?: number;

    nightTrain?: boolean;
    skiMode?: boolean;

    passClass?: number;
  }>
) => searchParamsSerializer(searchEndpoint, params);
